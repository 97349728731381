import request from '@/utils/request'

// 獲取推銷員
export function getSalesman(query) {
    return request({
      url: '/app/mallorder/salesperson',
      method: 'get',
      params: query
    })
}

// 保存
export function saveSalesman(data) {
    return request({
      url: '/app/mallorder/evaluate',
      method: 'post',
      data: data
    })
}