<template>
    <div class="cart_main">
        <div class="main" v-if="isShow">
            <van-row style="font-size:15px;">
                <van-col>請對您的銷售人員 {{salesmanData.name}} 進行評分</van-col>
                <van-col>Please rate your salesman</van-col>
            </van-row>
            <van-divider />
            <van-row>
                <van-field name="rate" label="評分Rate" label-width="50px">
                    <template #input>
                        <van-rate
                            v-model="star"
                            :size="25"
                            color="#ffd21e"
                            void-icon="star"
                            void-color="#eee"
                        />
                    </template>
                </van-field>
            </van-row>
            <van-row style="margin-top:20px;">
                    <van-col class="problem" span="24">
                        <van-field
                        v-model="remark"
                        rows="1"
                        label-width="60px"
                        autosize
                        clearable
                        label="留言Remark"
                        type="textarea"
                        maxlength="200"
                        show-word-limit
                        placeholder="请輸入留言"
                        />
                    </van-col>
            </van-row>
            <div style="margin-top: 30px;display:flex;">
                <van-button  block type="info" class="submint" @click="submint">提交<br/>Submit</van-button>
                <van-button  block type="info" plain  @click="back" class="back">返回<br/>Back</van-button>
            </div>
        </div>
    </div>
</template>
<script>
import { getSalesman ,saveSalesman} from '@/api/evaluation'
import { Notify } from "vant";
    export default {
        data() {
            return {
                star:0,
                remark:'',
                salesmanData:{},
                orderNum:''  ,
                isShow: false     
            }
        },
        created() {
            this.orderNum = this.$route.query.orderNum;
            getSalesman().then(res => {
                if(res.data) {
                    this.salesmanData = res.data;
                    this.isShow = true;
                }else {
                    this.$router.push('/order')
                }
            })
        },
        methods:{
            submint() {
                if(this.star < 1) {
                   Notify({ type: 'warning', message: '評分不能小於1星' });
                }else {
                    let params = {
                        comment: this.remark,
                        orderNum: this.orderNum,
                        salespersonId:this.salesmanData.id,
                        star: this.star
                    }
                    saveSalesman(params).then(res => {
                        if(res.code == 200) {
                            this.$router.push('/order')
                        }
                    })
                }
            },
            back() {
                this.$router.push('/order')
            }
        }
    }
</script>
<style scoped>
.cart_main {
    width: 93%;
    margin: 30px auto;
    background: #fff;
    border-radius: 20px;
}
.main {
    width: 90%;
    margin: 0 auto;
    padding: 30px 20px;
}
.problem{
    font-size: 15px;
    height: 60px;
    line-height: 60px;
}
.submint {
    background: linear-gradient(90deg, #64B046, #AEE845);
    color: #fff;
    border: none;
    width: 45%;
    margin: 0 auto;
    margin-bottom: 20px;
    font-size: 20px;
    padding: 60px 10px;
}
.problem {
    height: auto;
}
.back {
    border: 2px solid #64B046;
    width: 45%;
    margin: 0 auto;
    font-size: 20px;
    color: #64B046;
    padding: 60px 10px;
}
</style>